import { render, staticRenderFns } from "./appealRecordQuality.vue?vue&type=template&id=c874bf30&scoped=true&"
import script from "./appealRecordQuality.vue?vue&type=script&lang=js&"
export * from "./appealRecordQuality.vue?vue&type=script&lang=js&"
import style0 from "./appealRecordQuality.vue?vue&type=style&index=0&id=c874bf30&lang=less&scoped=true&"
import style1 from "./appealRecordQuality.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c874bf30",
  null
  
)

export default component.exports